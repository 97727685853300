<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    <span v-if="isNew">Nuevo</span>
                    <span v-if="!isNew">Editar</span>
                    artículo
                </div>

                <v-form
                  ref="form"
                  v-model="isValid"
                  autocomplete="off">
                <v-tabs v-model="tab" background-color="transparent" show-arrows>
                    <v-tabs-slider color="blue"></v-tabs-slider>

                    <v-tab href="#tab-art">Artículo</v-tab>
                    <v-tab href="#tab-cat">Categorías</v-tab>
                    <v-tab href="#tab-emp">Empresas</v-tab>
                    <v-tab href="#tab-pertar">Ctes./Provs.</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item :value="'tab-art'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <!-- FORMULARIO ARTICULO -->
                            <v-row>
                                <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="art.ArtCod"
                                        label="Código">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="11" md="7" lg="7" xl="5">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="art.ArtNom"
                                        required
                                        :rules="[v => !!v || 'El nombre es obligatorio']"
                                        label="Nombre *">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="7" md="5" lg="5" xl="3">
                                    <v-text-field
                                        class="mb-n7"
                                        outlined
                                        dense
                                        text
                                        v-model="art.ArtAbr"
                                        required
                                        label="Nombre abreviado">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <v-checkbox
                                        class="mt-n3 mb-n7"
                                        v-model="art.ArtExi"
                                        label="Control de existencias">
                                    </v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-7 mt-2" cols="12" sm="8" md="6" lg="6" xl="4">
                                    <v-textarea
                                        class="mt-0 mb-n7"
                                        outlined
                                        auto-grow
                                        v-model="art.ArtObs"
                                        label="Observaciones">
                                  </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-cat'">
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                              <!-- CATEGORIAS -->
                              <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                <!-- Botón añadir categoría -->
                                <v-btn
                                    :loading="loading"
                                    :disabled="editArtCat"
                                    @click="onBtnNuevaCategoria"
                                    color="primary"
                                    small
                                    class="mb-2">
                                    Añadir categoría
                                </v-btn>
                                
                                <v-data-table
                                    :headers="artCatHeaders"
                                    :items="artCatDataRows"
                                    :options.sync="artCatOptions"
                                    :footer-props="{itemsPerPageOptions: [100]}"
                                    :server-items-length="artCatTotalDataRows"
                                    :loading="artCatLoading"
                                    style="overflow: scroll"
                                    mobile-breakpoint="0"
                                    class="elevation-1"
                                    dense>
                                    <!-- eslint-disable-next-line vue/valid-v-slot -->
                                    <template v-slot:item.Opt="{ item }">
                                        <v-btn
                                            color="error"
                                            class="mr-2 my-1"
                                            small
                                            @click.stop="onBtnShowArtCatDeleteModal(item)"
                                        >
                                            <v-icon>
                                            mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <!-- NUEVA CATEGORÍA FORMULARIO -->
                                    <template v-if="editArtCat" slot="body.append">
                                        <tr class="new-row">
                                            <td>
                                                <ArtCatFind class="mb-n7" ref="artCatCtrl" v-model="newArtCat" :artCatToHide="artCatDataRows" label="Categoría"></ArtCatFind>
                                            </td>
                                            <td style="min-width: 250px" align ="right">
                                                <v-btn
                                                    :loading="artCatLoading"
                                                    :disabled="newArtCat == null"
                                                    @click="onBtnSaveCategoria"
                                                    color="primary"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                    Aceptar
                                                </v-btn>
                                                
                                                <v-btn
                                                    @click="onBtnCancelCategoria"
                                                    class="mt-2 mb-4 mr-2"
                                                    small>
                                                    Cancelar
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-emp'" eager>
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- EMPRESAS -->
                                <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                                    <EmpresasRelacionadas
                                        ref="relatedEmps"
                                        :empList="empDataList" 
                                        :empTotal="empDataTotal" 
                                        :loading="loading" 
                                        @update-data="updateEmpData"
                                        @emp-deleted="empDeleted"
                                    ></EmpresasRelacionadas>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item :value="'tab-pertar'" eager>
                        <v-card style="background-color: #F5F5F5" flat>
                            <v-row class="mt-0">
                                <!-- PERSONAS -->
                                <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Boton añadir persona -->
                                    <v-btn
                                        :loading="loading"
                                        :disabled="editNewPerTar"
                                        @click="onBtnNuevoPerTar"
                                        color="primary"
                                        small
                                        class="mb-2">
                                        Añadir cte./prov.
                                    </v-btn>

                                    <v-data-table
                                        :headers="perTarHeaders"
                                        :items="perTarDataRows"
                                        :options.sync="perTarOptions"
                                        :footer-props="{itemsPerPageOptions: [100]}"
                                        :server-items-length="perTarTotalDataRows"
                                        :loading="perTarLoading"
                                        style="overflow: scroll"
                                        mobile-breakpoint="0"
                                        class="elevation-1 perTarDt"
                                        dense>
                                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                                        <template v-slot:item.FechaDesde="{ item }">
                                            {{ formatDate(item.FechaDesde) }}
                                        </template>
                                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                                        <template v-slot:item.FechaHasta="{ item }">
                                            {{ formatDate(item.FechaHasta) }}
                                        </template>
                                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                                        <template v-slot:item.Opt="{ item }">
                                            <v-btn
                                                color="error"
                                                class="mr-2 my-1"
                                                small
                                                @click.stop="onBtnShowPerTarDeleteModal(item)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <!-- NUEVA PERSONA FORMULARIO -->
                                        <template v-if="editNewPerTar" slot="body.append">
                                            <tr class="new-row">
                                                <td style="min-width: 325px">
                                                    <EmpFind class="mb-n7" ref="newPerTarEmpCtrl" v-model="newPerTar.Emp" label="Empresa"></EmpFind>
                                                </td>
                                                <td style="min-width: 180px">
                                                    <OpeFind class="mb-n7" ref="newPerTarOpeCtrl" v-model="newPerTar.Ope" label="Operación"></OpeFind>
                                                </td>
                                                <td style="min-width: 230px">
                                                    <PerFind class="mb-n7" ref="newPerTarPerCtrl" v-model="newPerTar.Per" label="Persona"></PerFind>
                                                </td>
                                                <td style="min-width: 175px">
                                                    <div class="mb-n7">
                                                        <DateFind label="Desde" v-model="newPerTar.FechaDesde"></DateFind>
                                                    </div>
                                                </td>
                                                <td style="min-width: 175px">
                                                    <div class="mb-n7">
                                                        <DateFind  label="Hasta" v-model="newPerTar.FechaHasta"></DateFind>
                                                    </div>
                                                </td>
                                                <td style="min-width: 235px" align="right">
                                                    <v-btn
                                                        :loading="perTarLoading"
                                                        :disabled="!isValidPerTar(newPerTar)"
                                                        @click="onBtnSavePerTar"
                                                        color="primary"
                                                        class="mt-2 mb-4 mr-2"
                                                        small>
                                                        Aceptar
                                                    </v-btn>
                                                    
                                                    <v-btn
                                                        @click="onBtnCancelPerTar"
                                                        class="mt-2 mb-4 mr-2"
                                                        small>
                                                        Cancelar
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="!isValid"
                        @click="onBtnSave"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Aceptar
                    </v-btn>
                    
                    <v-btn
                        to="/articulos"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px">
                        Cancelar
                    </v-btn>

                    <!-- && canDelete" -->
                    <v-btn v-if="!isNew"
                        color="error"
                        class="mt-2 mb-4"
                        style="width:100px"
                        @click="onBtnShowDeleteModal()">
                        Borrar
                    </v-btn>
                </v-row>
                </v-form>
            </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar Articulo -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR ARTÍCULO
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar el artículo? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="onBtnDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         
        <!-- Borrar Categoria -->
        <v-dialog
            v-model="showArtCatDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR CATEGORÍA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la categoría?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="artCatLoading"
                        color="primary"
                        @click="onBtnArtCatDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelArtCatDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Borrar persona -->
        <v-dialog
            v-model="showPerTarDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR PERSONA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la persona?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="perTarLoading"
                        color="primary"
                        @click="onBtnPerTarDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelPerTarDelete">
                        Cancelar
                    </v-btn>
                  </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ArtCatFind from "../components/ArtCatFind.vue";
import DateFind from "../components/DateFind.vue";
import EmpFind from "../components/EmpFind.vue";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
import OpeFind from "../components/OpeFind.vue";
import PerFind from "../components/PerFind.vue";
export default ({
    components: {
        ArtCatFind, DateFind, EmpFind, EmpresasRelacionadas, OpeFind, PerFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            // canDelete: false, // Si el articulo esta presente en algun documento no se podrá borrar
            showDeleteDialog: false,
            showArtCatDeleteDialog: false,
            showPerTarDeleteDialog: false,
            art: {
                ArtId: 0,
                ArtCod: '',
                ArtNom: '',
                ArtAbr: '',
                ArtExi: false,
                ArtObs: ''
            },
            // Tabla categorias
            artCatLoading: false,
            artCatTotalDataRows: 0,
            artCatDataRows: [],
            artCatOptions: {itemsPerPage: 100},
            artCatHeaders: [
                { text: 'Categorías relacionadas', width:300, sortable: false, value: 'ArtCatNom' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Datatable articulos (PerTar)
            perTarLoading: false,
            perTarTotalDataRows: 0,
            perTarDataRows: [],
            perTarOptions: {itemsPerPage: 100},
            perTarHeaders: [
                { text: 'Empresa', width:325, sortable: false, value: 'Emp.EmpNom' },
                { text: 'Operación', width:100, sortable: false, value: 'Ope.OpeNom' },
                { text: 'Persona', width:200, sortable: false, value: 'Per.PerNom' },
                { text: 'Fecha desde', width:100, sortable: false, value: 'FechaDesde' },
                { text: 'Fecha hasta', width:100, sortable: false, value: 'FechaHasta' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Editar categorias
            editArtCat: false, // Indica si se esta editando la nueva categoria
            newArtCat: {},
            selectedArtCat: null, // Indica la categoria seleccionada para el borrado
            // Edición personas
            editNewPerTar: false, // Indica si se esta editando el nuevo articulo
            newPerTar: {},
            selectedPerTar: null, // Indica el articulo seleccionado para el borrado
        }
    },
    // watch: {
    //   art: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.art.ArtId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/articulos');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_ART',
            ArtId: this.art.ArtId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Art;
            const perTar = result.data.PerTar;
            // const canDelete = result.data.CanDelete;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item,
                  perTar
                  // canDelete
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar el artículo.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        const artCatIds = this.artCatDataRows.map(m => m.ArtCatId);
        const empIds = this.empDataList.map(m => m.EmpId);
        const perTars = this.perTarDataRows.map(m => { 
          return { 
            EmpId: m.EmpId, 
            OpeId: m.OpeId, 
            PerId: m.PerId,
            FechaDesde: m.FechaDesde,
            FechaHasta: m.FechaHasta
          };
        });

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            EmpId: this.empId,
            Art: this.art,
            ArtCatIds: artCatIds,
            EmpIds: empIds,
            PerTars: perTars
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el artículo.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            ArtId: this.art.ArtId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar el artículo.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Artículo no encontrado.');
              this.goToList();
              return;
            }

            this.art = data.item;
            // this.canDelete = data.canDelete
            
            this.empDataTotal = data.item.ArtEmp.length;
            this.empDataList = [];
            data.item.ArtEmp.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });

            this.artCatTotalDataRows = data.item.ArtCatArt.length;
            this.artCatDataRows = [];
            data.item.ArtCatArt.forEach(i => {
              this.artCatDataRows.push({
                ArtCatId: i.ArtCat.ArtCatId,
                ArtCatNom: i.ArtCat.ArtCatNom
              });
            });

            this.perTarTotalDataRows = data.perTar.length;
            this.perTarDataRows = data.perTar;
          });
      },
      //#endregion

      //#region Añadir categorías - personas
      onBtnNuevaCategoria() {
        this.editArtCat = true;
        this.newArtCat = null;
      },
      onBtnNuevoPerTar() {
        this.editNewPerTar = true;
        this.newPerTar = { Ope: null, Emp: null, Per: null, FechaDesde: null, FechaHasta: null };
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }
          this.postDataToApi().then(data => {
            if(data.item.ArtId != null) {
              this.goToList();
            }
            else if(data.item.Message != null) {
              alert(data.item.Message);
            }
            else {
              alert('Error al guardar el artículo.');
            }
          });
      },
      onBtnSaveCategoria() {
        if(this.newArtCat == null) { return; }
        this.artCatDataRows.push(this.newArtCat);
        this.artCatTotalDataRows += 1;
        this.newArtCat = null,
        this.editArtCat = false; 
      },
      addEmpIfNotExist(emp) {
        const empCount = this.empDataList.filter(f => f.EmpId == emp.EmpId).length;
        // Si no existe añadimos la empresa
        if(empCount == 0) {
          const newEmp = {
            EmpId: emp.EmpId,
            EmpNom: emp.EmpNom
          };
          this.empDataList.push(newEmp);
          this.empDataTotal += 1;
        }
      },
      onBtnSavePerTar() {
        if(!this.isValidPerTar(this.newPerTar)) { return; }
        
        this.newPerTar.EmpId = this.newPerTar.Emp.EmpId;
        this.newPerTar.OpeId = this.newPerTar.Ope.OpeId;
        this.newPerTar.PerId = this.newPerTar.Per.PerId;
        this.perTarDataRows.push(this.newPerTar);
        this.perTarTotalDataRows += 1;

        this.addEmpIfNotExist(this.newPerTar.Emp);
        
        this.newPerTar = {};
        this.editNewPerTar = false;
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            //alert("Error al borrar el artículo.");
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Cancelar creación de categoría - personas
      onBtnCancelCategoria() {
        this.editArtCat = false;
      },
      onBtnCancelPerTar() {
        this.editNewPerTar = false;
        this.newPerTar = {};
      },
      //#endregion

      //#region Eliminar categorías
      removeArtCat(item) {
        var index = this.artCatDataRows.map(m => m.ArtCatId).indexOf(item.ArtCatId);
        if (index != -1) {
          this.artCatDataRows.splice(index, 1);
          this.artCatTotalDataRows -= 1;  
        }
      },
      removePerTar(item) {
        var index = this.perTarDataRows.indexOf(item);
        if (index != -1) {
          this.perTarDataRows.splice(index, 1);
          this.perTarTotalDataRows -= 1;
        }
      },
      removeEmpPerTars(emp) {
        const empPerTars = this.perTarDataRows.filter(f => f.EmpId == emp.EmpId);
        empPerTars.forEach(perTar => {
          this.removePerTar(perTar);
        });
      },
      onBtnArtCatDelete() {
        this.removeArtCat(this.selectedArtCat);
        this.selectedArtCat = null;
        this.showArtCatDeleteDialog = false;
      },
      onBtnPerTarDelete() {
        this.removePerTar(this.selectedPerTar);
        this.selectedPerTar = null;
        this.showPerTarDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnShowArtCatDeleteModal(item) {
        this.selectedArtCat = item;
        this.showArtCatDeleteDialog = true;
      },
      onBtnShowPerTarDeleteModal(item) {
        this.selectedPerTar = item;
        this.showPerTarDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelArtCatDelete() {
        this.selectedArtCat = null;
        this.showArtCatDeleteDialog = false;
      },
      onBtnCancelPerTarDelete() {
        this.selectedPerOpe = null;
        this.showPerOpeDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      empDeleted(item) {
        this.removeEmpPerTars(item);
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
          // if (this.art == null) {
          //   this.isValid = false;
          //     return;
          // }
          // this.isValid = this.art.ArtNom != null && this.art.ArtNom != '';
      },
      isValidPerTar(perTar) {
        if(perTar.Emp == null) { return false; }
        if(perTar.Ope == null) { return false; }
        if(perTar.Per == null) { return false; }
        if(perTar.FechaDesde == null) { return false; }
        if(perTar.FechaHasta == null) { return false; }
        
        const equalPerTars = this.perTarDataRows
          .filter(f => f.EmpId == perTar.Emp.EmpId && f.OpeId == perTar.Ope.OpeId && f.PerId == perTar.Per.PerId);
        return equalPerTars.length == 0;
      },
      //#endregion

      formatDate (value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
      }
    } 
})
</script>
