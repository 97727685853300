<template>
    <v-autocomplete
        v-model="value"
        cache-items
        dense
        :small-chips="smallChips"
        :deletable-chips="deletableChips"
        :hide-selected="multiple"
        :items="itemsList"
        :search-input.sync="searchItem"
        open-on-clear
        item-value="ArtCatId"
        item-text="ArtCatNom"
        outlined
        no-data-text="Introduzca texto a buscar"
        :label="label"
        :readonly="readonly"
        return-object
        ref="ArtCatFindCtrl"
        :multiple="multiple"
        :clearable="!readonly"
        :menu-props="{'open-on-click': true}"
        :disabled="disabled"
        @click="clickEvt"
        @click:append="clickAppendEvt"
        @input="inputEvt"
        @change="changeEvt"
        @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'ArtId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null
    }),
    props: [
      'value', 'label','artCatIdParent', 'showParentsOnly', 'showNoParentOnly',
      'artCatToHide', 'multiple', 'readonly', 'defValue', 'smallChips', 
      'deletableChips', 'disabled', 'selectOnReadonly'
    ],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      artCatIdParent() {
        this.$refs.ArtCatFindCtrl.cachedItems = [];
        this.loadItems();
      },
      value() {
        if (this.value != null) {
          this.loadItems();
        }
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {
            Action: 'GET_DATA_LIST_PAGINATED',
            ArtCatNom: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            ArtCatIdParent: this.artCatIdParent,
            ShowParentsOnly: this.showParentsOnly,
            ShowNoParentOnly: this.showNoParentOnly,
            Searcher: true
        };
        
        var valorActual = this.value;
        var valorDefecto = this.defValue;
        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/ArtCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          
          const idsToHide = this.artCatToHide != null ? this.artCatToHide.map(m => m.ArtCatId) : [];
          this.itemsList = [];
            result.data.EntsList.forEach(element => {
              if(idsToHide.indexOf(element.ArtCatId) == -1) {
                var itemObject = {ArtCatId: element.ArtCatId, ArtCatNom: element.ArtCatNom}
                this.itemsList.push(itemObject);
              }
            });

            if(valorActual != null) {
              // if (valorActual.length == null) {  
              if (!Array.isArray(valorActual)) {  // es un objeto
                  if (valorActual.ArtCatId != null) {
                    if (!this.itemsList.some(itm=>itm.ArtCatId === valorActual.ArtCatId)) {
                      this.itemsList.push(valorActual);
                    }
                  }
                  else if (valorDefecto != null) {
                    var itmDefault = this.itemsList.find(itm=>itm.ArtCatId === Number(valorDefecto))
  
                    if (!this.itemsList.some(itm=>itm.ArtCatId === Number(valorDefecto))) {
                      this.itemsList.push(itmDefault);
                    }
                    this.value = itmDefault;
                    this.$emit('input', this.value);
                  }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.ArtCatId === element.ArtCatId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        })
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.ArtCatFindCtrl.isMenuActive) {
            this.$refs.ArtCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.ArtCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.ArtCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems('');
      // if (this.artCatIdParent != null) {
      // }
    },
  }
</script>